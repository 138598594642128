.ResizableBoxContainer {
  position: fixed;
  top: 65px;
  z-index: 1;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
}

.ResizableBoxContainer:nth-child(2) {
  flex: 1;
}
.ResizableBoxContainer.ResizableBoxContainerLeft {
}
.ResizableBoxContainer.ResizableBoxContainerRight {
  right: 10px;
}

.react-resizable {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-width: inherit;
  max-width: inherit;
  height: 100%;
  width: 100%;
}
.react-resizable-handle {
  display: flex;
  position: absolute;
  cursor: s-resize;
  width: 80%;
  height: 10px;
  bottom: -5px;
  border-radius: 5px;
  background: transparent;
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 0.2s linear;
}

.react-resizable-handle:hover,
.react-resizable-handle:active {
  background: rgb(170, 170, 170);
}

.ResizableBox {
  max-height: calc(100vh - 80px);
}

.ResizableBox > .react-resizable-handle {
  cursor: e-resize;
  height: 80%;
  width: 10px;
  left: auto;
  right: -3px;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.ResizableBoxContainerRight .ResizableBox > .react-resizable-handle {
  cursor: w-resize;
  height: 80%;
  width: 10px;
  left: -5px;
  right: auto;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
}
