.styled-table {
  display: flex;
  position: relative;
  flex-direction: column;
  border-collapse: collapse;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
  border-radius: 10px !important; /* Add !important */
  width: 100%;
  padding: 0 !important;
  font-size: 0.8rem;
}

.styled-table .MuiFormControl-root {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  padding: 2px 15px;
}

.styled-table .MuiAutocomplete-root {
  width: 100%;
}

.styled-table .MuiFormControl-root fieldset {
  top: 0;
}
.styled-table .MuiFormControl-root legend {
  display: none;
}
.styled-table .MuiFormLabel-root {
  -webkit-transition: none !important;
  transition: none !important;
  transform: none !important;
  -webkit-transform: none !important;
  user-select: none;
  position: relative !important;
  max-width: calc(133% - 32px) !important;
  display: flex !important;
  align-items: center;
  width: 50%;
  color: inherit;
  font-size: 0.8rem;
}
.styled-table .MuiInputBase-root {
  width: 50%;
  max-width: 50%;
  font-size: 0.8rem;
}

.styled-table .MuiOutlinedInput-input {
  padding: 5.5px 14px;
}

.styled-table .collapseChildrenWrapper {
  width: 100%;
  display: flex;
  flex-direction: column !important;
}

.styled-table .collapseChildrenWrapper > * {
  padding-left: 25px !important;
}

.styled-table .collapseChildrenWrapper .collapseChildrenWrapper {
  padding-left: 0px !important;
}

.styled-table .collapseChildrenWrapper .collapseChildrenWrapper .collapsableSectionHeader {
  padding-left: 45px !important;
}

.styled-table .collapseChildrenWrapper .collapsableSectionHeader {
  padding-left: 24px !important;
}

.styled-table .collapseChildrenWrapper .collapseChildrenWrapper > * {
  padding-left: 55px !important;
}

.styled-table .collapseChildrenWrapper > .reorderGroup {
  padding-left: 0px !important;
}

.styled-table .collapsableSectionHeader {
  background-color: #e1e1e1;

  width: 100%;
  padding: 7.5px 15px;
  display: flex;
  align-items: center;
}

.styled-table .collapsableSectionHeader .MuiButtonBase-root {
  margin-right: 6px;
}

.styled-table .collapsableSectionHeader .MuiButtonBase-root:nth-child(2) {
  margin-right: 0px;
  margin-left: auto;
}

.styled-table .reorderItem .collapsableSectionHeader .MuiButtonBase-root:nth-child(2) {
  margin-right: 8px;
  margin-left: auto;
}

.styled-table .collapseChildrenWrapper .collapseChildrenWrapper .collapsableSectionHeader:nth-child(odd) {
  background-color: #e1e1e1;
}

.styled-table .MuiButtonBase-root {
  padding: 0px;
  width: 18px;
  min-width: auto;
  max-height: min-content;
  aspect-ratio: 1;
  background: var(--secondary-color);
}

.styled-table .MuiCheckbox-root {
  padding: 0px;
  width: 25px;
  aspect-ratio: 1;
  margin: 4px 0px;
  min-width: auto;
  background: none;
  color: var(--secondary-color);
  padding: 5.5px 0px;
}

.styled-table .MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 24px;
}

.styled-table .MuiSvgIcon-root {
  font-size: 18px;
}

.styled-table .MuiFormControlLabel-root {
  flex-direction: row-reverse;
  width: 100%;
  margin: 0;
  justify-content: flex-end;
  padding: 2px 15px;
  font-size: 0.8rem;
}

.styled-table .MuiFormControlLabel-root .MuiTypography-root {
  width: 50%;
  aspect-ratio: 0;
  font-size: 0.8rem;
}

.styled-table .MuiFormControlLabel-root .MuiButtonBase-root {
  justify-content: start;
  color: var(--secondary-color);
}

.styled-table > .MuiFormControl-root:nth-child(odd) {
  background-color: #f3f3f3;
}

.styled-table > .multiInput:nth-child(odd) {
  background-color: #f3f3f3;
}

.styled-table .collapseChildrenWrapper > .MuiFormControl-root:nth-child(odd) {
  background-color: #f3f3f3;
}

.styled-table > .MuiAutocomplete-root:nth-child(odd) {
  background-color: #f3f3f3;
}

.styled-table .collapseChildrenWrapper > .MuiAutocomplete-root:nth-child(odd) {
  background-color: #f3f3f3;
}

.styled-table .collapseChildrenWrapper > .MuiFormControlLabel-root:nth-child(odd) {
  background-color: #f3f3f3;
}

.styled-table .reorderGroup > .reorderItem:nth-child(odd) .collapsableSectionHeader {
  background-color: #edecec;
}

.styled-table .collapseChildrenWrapper .MuiAutocomplete-root > .MuiFormControl-root {
  padding-left: 0px !important;
}

.styled-table .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 10px;
}

.styled-table .MuiAutocomplete-hasClearIcon.css-1p6gcs-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 28px;
}

.styled-table .MuiAutocomplete-root .MuiButtonBase-root {
  padding: inherit;
  width: inherit;
  min-width: inherit;
  max-height: inherit;
  aspect-ratio: inherit;
  background: inherit;
}

.styled-table .reorderItem {
  position: relative;
}

.styled-table .multiInput {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 9px !important;
  padding-right: 15px;
}

.styled-table input[aria-hidden='true'] {
  display: none;
}

.styled-table.disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.38);
}

.styled-table.disabled .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.38);
}

.styled-table.disabled .MuiInputBase-root {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.styled-table.disabled .MuiInputBase-input {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.styled-table.disabled .MuiButtonBase-root {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.styled-table.disabled .MuiCheckbox-root {
  color: rgba(0, 0, 0, 0.26);
  background: none;
}
