.CanvasArea {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.CanvasArea-Relative {
  position: absolute;
  width: auto;
  height: auto;
}

.CanvasArea .ProducedBbox {
  background-color: #4184d1;
  opacity: 0.5;
  position: absolute;
}

.AreaObject {
  position: absolute;
  display: block;
  cursor: pointer;
}

.AreaObject {
  box-sizing: initial;
  border: solid 5px transparent;
  /* box-shadow: inset 0 0 0 2px #1968c3; */
  position: absolute;
  cursor: pointer;
  border-radius: 2px;
  transform: translate(-5px, -5px);
}

.AreaObject.AreaObjectSelected {
  box-shadow: inset 0 0 0 2px #d3484880;
}

.AreaObjectInnerInner {
  position: absolute; /* Added this line to make top, left work */
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  top: 5px;
  left: 5px;
}

.AreaObject > .AreaObjectInnerInner {
  position: absolute;
  cursor: auto;
}

.AreaObject.Hidden {
  display: none;
}

.react-draggable {
  border: dashed 2.5px #d34848;
}

.react-draggable:focus-visible {
  outline: #ff0000 auto 1px;
}

.react-draggable .resizeHandles * {
  width: 12px !important;
  height: 12px !important;
}

.react-draggable .resizeHandles *:nth-child(1) {
  border-top: solid 7px #d34848;
  transform: translate(-50%, 0);
  left: 50% !important;
  margin-left: -6px;

  top: -4px !important;
}

.react-draggable .resizeHandles *:nth-child(2) {
  border-right: solid 7px #d34848;
  top: 50% !important;
  right: -4px !important;
  margin-top: -6px;
}

.react-draggable .resizeHandles *:nth-child(3) {
  border-bottom: solid 7px #d34848;
  left: 50% !important;
  margin-left: -6px;

  transform: translate(-50%, 0);
  bottom: -4px !important;
}

.react-draggable .resizeHandles *:nth-child(4) {
  border-left: solid 7px #d34848;
  top: 50% !important;
  left: -4px !important;
  margin-top: -6px;
}

.react-draggable .resizeHandles *:nth-child(5) {
  border-top: solid 7px #d34848;
  border-right: solid 7px #d34848;
  transform: translate(-50%, 0);
  top: -4px !important;
}

.react-draggable .resizeHandles *:nth-child(6) {
  border-bottom: solid 7px #d34848;
  border-right: solid 7px #d34848;
  transform: translate(-50%, 0);
  bottom: -4px !important;
}

.react-draggable .resizeHandles *:nth-child(7) {
  border-bottom: solid 7px #d34848;
  border-left: solid 7px #d34848;
  transform: translate(50%, 0);
  bottom: -4px !important;
}

.react-draggable .resizeHandles *:nth-child(8) {
  border-top: solid 7px #d34848;
  border-left: solid 7px #d34848;
  transform: translate(50%, 0);
  top: -4px !important;
}

.selectBox {
  position: relative;
  width: 0;
  height: 0;
}

.selectBox * {
  position: absolute;
  cursor: pointer;
  padding: 2px;
}
.selectBox *:nth-child(1) {
  box-shadow: 0 -2px 0 0 #1968c3 inset;
  margin-top: -6px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
.selectBox *:nth-child(2) {
  box-shadow: 2px 0 0 0 #1968c3 inset;
  margin-left: -4px;

  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.selectBox *:nth-child(3) {
  box-shadow: 0 2px 0 0 #1968c3 inset;
  margin-top: -6px;

  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
.selectBox *:nth-child(4) {
  box-shadow: -2px 0 0 0 #1968c3 inset;
  margin-left: -8px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.selectBox.selected *:nth-child(1) {
  box-shadow: 0 -2px 0 0 #d3484880 inset;
}
.selectBox.selected *:nth-child(2) {
  box-shadow: 2px 0 0 0 #d3484880 inset;
}
.selectBox.selected *:nth-child(3) {
  box-shadow: 0 2px 0 0 #d3484880 inset;
}
.selectBox.selected *:nth-child(4) {
  box-shadow: -2px 0 0 0 #d3484880 inset;
}

.resizeChildren {
  position: absolute;
}
