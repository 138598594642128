.MuiTreeItem-content {
  border-radius: 5px;
  padding: 2px !important;
}

.MuiTreeItem-label {
  display: inline-flex;
  align-items: center;
}

.MuiTreeItem-label > div {
  display: inline-flex;
  align-items: center;
}

.MuiTreeItem-label svg {
  margin-right: 4px;
  font-size: 18px;
}

.MuiTreeItem-content:hover {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.04);
}
.MuiTreeItem-content.Mui-selected {
  background-color: rgb(85 108 214 / 28%);
}

.MuiTreeItem-content .MuiTreeItem-iconContainer:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.TreeLabel {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  cursor: pointer;
  user-select: none;
}

.DraggingTop > .TreeLabel {
  border-top: solid rgba(0, 0, 0, 0.1) 10px;
}

.DraggingBottom > .TreeLabel {
  border-bottom: solid rgba(0, 0, 0, 0.1) 10px;
}

.DragAndDrop {
  display: flex;
  width: 100%;
}

.TreeLabel div {
  color: black;
}

.TreeLabel .DOMLabel {
  display: flex;
  flex-direction: row;
}

.TreeLabel .DOMLabelContent {
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TreeLabel svg {
  color: inherit;
}

.TreeLabelButtons {
  margin-left: auto;
  display: none;
  align-items: center;
}

.TreeLabelButtons button {
  opacity: 0.6;
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0px 1px;
  cursor: pointer;
}
.TreeLabelButtons button * {
  font-size: 14px;
}

.MuiTreeItem-label:hover .TreeLabelButtons {
  display: flex;
}

.TreeLabelIcon {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  text-align: center;
  color: var(--primary-color);
}

.TreeLabel.DOMTreeLabel svg {
  color: #585e8b;
}
