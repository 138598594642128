:root {
  /* Primary colors */
  --primary-color: #1b3048; /* purple */
  --secondary-color: #466586; /* Gray */

  /* Text colors */
  --text-color: #000000; /* Dark Gray */
  --text-gray: gray; /* Dark Gray */
  --text-light: #fff; /* White */

  /* Background colors */
  --background-color: #f3f5f7; /* Light Gray */
  --background-dark: #343a40; /* Dark Gray */
  --paper-color: #fff; /* White */
  --light-gray-color: #f7f6f2;

  /* Accent colors */
  --success-color: #5e8660; /* Green */
  --info-color: #6b6969; /* Cyan */
  --warning-color: #ffc107; /* Yellow */
  --error-color: #ee3d3d; /* Red */

  /* Custom colors */
  --custom-color-1: #6f42c1; /* Purple */
  --custom-color-2: #fd7e14; /* Orange */
  --custom-color-3: #20c997; /* Teal */
  --custom-color-4: #007bff; /* Blue */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;

  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 5px;
  transition: background-color 0.2s linear;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.logoContainer {
  width: 57px;
  overflow: hidden;
  margin: 7px;
  transition: width 0.2s ease;
  margin-top: 15px;
}

.logoContainer.logoContainerOpen {
  width: 190px;
}

.logo {
  width: 160px;
}

.MuiPaper-root {
  background: var(--primary-color) !important;
  color: #fff !important;
}

.MuiDrawer-paper {
  overflow: hidden;
}

.MuiList-root .MuiButtonBase-root {
  border-radius: 10px;
}

.MuiPaper-root .MuiSvgIcon-root {
  color: #fff !important;
  margin-left: 6px;
}

.MuiToolbar-root {
  justify-content: center;
}

.MuiToolbar-root .MuiSvgIcon-root {
  margin-left: 0;
}

.MuiPaper-root .Mui-selected {
  background: #dfdfdf !important;
  color: var(--primary-color) !important;
  border-radius: 10px;
}

.MuiPaper-root .Mui-selected .MuiSvgIcon-root {
  color: var(--primary-color) !important;
}

.MuiPickersDay-root {
  color: white !important;
}

.MuiDayCalendar-weekDayLabel {
  color: rgba(255, 255, 255, 0.753) !important;
}
